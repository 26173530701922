import { Link } from "react-router-dom";
import React, { useState } from 'react';
import "./component.css";

const Component = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState("");

  const handleSelect = (floor) => {
    setSelectedFloor(floor);
    setIsOpen(false);
  };

  return (
    <>
      <div className="my-top" id="features">
        <center>
          <p className="stay-theam">Our Facilities</p>
          <hr class="stay-line" />
        </center>
        <div className="back-color">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-lg-6">
                <img src="img/calling.png" className="img-fluid calling" alt="No-image" />
              </div>
              <div className="col-xl-9 col-lg-6">
                <div className="row right-side_image">
                  <img src="img/logohut2.png" className=" sidehut" alt="no-image" />
                  <div className="col-xl-4 col-lg-12 col-md-6 col-sm-6">
                    <div className="facilities-card">
                      <i className="ri-home-heart-line home_icon"></i>
                      <p className="faci_txt">Fitness Center </p>
                      <p className="faci_txt2">Modern equipment for a complete workout.</p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-12 col-md-6 col-sm-6">
                    <div className="facilities-card">
                      <i className="ri-home-heart-line home_icon"></i>
                      <p className="faci_txt">Top Restaurant</p>
                      <p className="faci_txt2">Gourmet dining with exceptional service and ambiance. </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-12 col-md-6 col-sm-6">
                    <div className="facilities-card">
                      <i className="ri-home-heart-line home_icon"></i>
                      <p className="faci_txt">Best Suites</p>
                      <p className="faci_txt2">Spacious, elegant suites for ultimate comfort and relaxation.</p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-12 col-md-6 col-sm-6">
                    <div className="facilities-card">
                      <i className="ri-home-heart-line home_icon"></i>
                      <p className="faci_txt">Swimming Pool</p>
                      <p className="faci_txt2">Pristine pool for swimming, lounging, and sunbathing.</p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-12 col-md-6 col-sm-6">
                    <div className="facilities-card">
                      <i className="ri-home-heart-line home_icon"></i>
                      <p className="faci_txt">Spa & Wellness</p>
                      <p className="faci_txt2">Luxurious treatments to refresh and rejuvenate. </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>

  );
};

export default Component;
