import { Link } from "react-router-dom";
import React, { useState } from 'react';
import "./component.css";

const Component = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState("");

  const handleSelect = (floor) => {
    setSelectedFloor(floor);
    setIsOpen(false);
  };
  return (
    <>
      <div className="my-top containerP" id="features">
        <img src="img/producthead.png" alt="Nature" className="img-fluid head_image" />
        <div className="text-block">
          <p className="product_head01">Products</p>
          <hr className="line3" />
        </div>
      </div>
    </>
  );
};
export default Component;
