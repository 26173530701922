// import Topbar from "../components/topbar/Component.js";
import Nav from "../components/nav/Component.js";
import Aboutcompany from "../components/aboutcompany/Component.js";
import Productpage from "../components/productpage/Component.js";
import { Helmet } from "react-helmet";

import "./../css/style.css";

const Component = () => {
  return (
    <>
      <Helmet>
        <title>Hypertonic IT Solutions</title>
      </Helmet>
      
      <Nav />
      {/* <Productpage /> */}
      <Aboutcompany />
      {/* <Footer2 /> */}
    </>
  );
};

export default Component;
