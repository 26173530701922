import { Link } from "react-router-dom";
import React, { useState } from 'react';
import "./component.css";

const Component = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState("");

  const handleSelect = (floor) => {
    setSelectedFloor(floor);
    setIsOpen(false);
  };

  return (
    <>

      <div className="my-top" id="features">
        
      <center>
          <p className="stay-theam">About Us</p>
          <hr class="stay-line" />
        </center>
        <div className="product_side">
          <div className="container-fluid">
            <div className="row ">
              <div className="col-md-6">
                <img src="img/about.png" className="img-fluid about" alt="No-image" />
              </div>
              <div className="col-md-6 flx">
                <div className="about_back">
                  <img src="img/abouthut.png" className="img-fluid about_hut" alt="No-image" />
                  <p className="about_txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                  <p>There are many variations of passages of lorem free market to available, but the majority have alteration in some form, by injected humour, or randomised words There are many variations of passages of lorem free market to available, but the majority have alteration in some form, by injected humour, or randomised words There are many variations of passages of lorem free market to available, but the majority have alteration in some form, by injected humour, or randomised words</p>
                  <p>There are many variations of passages of lorem free market to available, but the majority have alteration in some form, by injected humour, or randomised words There are many variations of passages of lorem free market to available, but the majority have alteration in some form, by injected humour, or randomised words There are many variations of passages of lorem free market to available, but the majority have alteration in some form, by injected humour, or randomised words</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>

  );
};

export default Component;
