import { Link } from "react-router-dom";
import React, { useState } from 'react';
import Productpage from "../productpage/Component.js";
// import Newcustomer from "./newcustomer/Component.js";
import Footer from "../footer/Component.js";
import "./component.css";

const Component = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState("");

  const handleSelect = (floor) => {
    setSelectedFloor(floor);
    setIsOpen(false);
  };

  return (
    <>
      <Productpage />
      <div className="container my-top" id="checkout">
        <div className="table-responsive">
          <table className="table table-light my_table">
            <thead>
              <tr>
                <th>Room/Addition Services</th>
                <th>Checkin / Checkout</th>
                <th>Capacity</th>
                <th>Night</th>
                <th>Quantity</th>
                <th>Deposit Payment</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Lorem Ipsum is simply jnj</td>
                <td>May 29, 2024 - May 31, 2024</td>
                <td>1 adults,
                  0 childs</td>
                <td>9</td>
                <td>10</td>
                <td>Pending</td>
                <td>2000/-</td>
              </tr>

              <tr>
                <td>Lorem Ipsum is simply jnj</td>
                <td>May 29, 2024 - May 31, 2024</td>
                <td>1 adults,
                  0 childs</td>
                <td>9</td>
                <td>10</td>
                <td>Pending</td>
                <td>2000/-</td>
              </tr>

              <tr>
                <td>Lorem Ipsum is simply jnj</td>
                <td>May 29, 2024 - May 31, 2024</td>
                <td>1 adults,
                  0 childs</td>
                <td>9</td>
                <td>10</td>
                <td>Pending</td>
                <td>2000/-</td>
              </tr>
              <tr>
                <td>Lorem Ipsum is simply jnj</td>
                <td>May 29, 2024 - May 31, 2024</td>
                <td>1 adults,
                  0 childs</td>
                <td>9</td>
                <td>10</td>
                <td>Pending</td>
                <td>2000/-</td>
              </tr>
              <tr>
                <td colSpan={6}>Total</td>
                <td>92000/-</td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>

      {/* <Newcustomer /> */}
      <Footer />
    </>

  );
};

export default Component;
