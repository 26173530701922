import { Link } from "react-router-dom";
import React, { useState } from 'react';
import Productpage from "../productpage/Component.js";

import Footer from "../footer/Component.js";
import "./component.css";

const Component = () => {

  return (
    <>
      <Productpage />
      <div className="container">
        <div className="row contact-top">
          <div className="col-md-5">
            <h3 className="message-head">
              Leave us a message
            </h3>
            <p className="message-txt">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
            </p>
            <div className="social">
              <div className="social-icon">
                <i class="ri-whatsapp-line"></i>
              </div>
              <div className="social-icon">
              <i class="ri-instagram-line"></i>
              </div>
              <div className="social-icon">
              <i class="ri-mail-open-line"></i>
              </div>
              <div className="social-icon">
                <i class="ri-linkedin-line"></i>
              </div>
              <div className="social-icon">
                <i class="ri-youtube-line"></i>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <form action="#" className="form-top">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <input type="text" className="form-control mrinpp" id="name" placeholder="Enter Name" name="name" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input type="text" className="form-control mrinpp" id="enmail" placeholder="Enter Email" name="enmail" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input type="text" className="form-control mrinpp" id="phone" placeholder="Enter Phone" name="phone" />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <textarea className="form-control" rows="5" id="comment"></textarea>
                  </div>
                </div>
              </div>
              <button type="submit" class="srarch_bot">Submit</button>
            </form>
          </div>

        </div>
      </div>
      <div className="add-top">
      <div className="container ">
        <div className="row ">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="add-address">
            <i class="ri-map-pin-line location-map"></i>
            <p className="add-txt">Service Area</p>
            <p className="add-txt1">Whole of Greater Toronto Area</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="add-address">
            {/* <i class="ri-map-pin-line "></i> */}
            <i class="ri-phone-line location-map"></i>
            <p className="add-txt">Service Area</p>
            <p className="add-txt1">Whole of Greater Toronto Area</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="add-address">
            <i class="ri-mail-send-line location-map"></i>
            {/* <i class="ri-map-pin-line "></i> */}
            <p className="add-txt">Service Area</p>
            <p className="add-txt1">Whole of Greater Toronto Area</p>
            </div>
          </div>
        </div>
        </div>
      </div>
          <div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3569.140218697207!2d77.31053741100317!3d28.39720029462764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdd2e7679ce6b%3A0x36927f402b606532!2s%26work%20Co-working!5e1!3m2!1sen!2sin!4v1726049314371!5m2!1sen!2sin" width="100%" height="450" ></iframe>
          </div>
      <Footer />
    </>

  );
};

export default Component;
