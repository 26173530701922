import { Link } from "react-router-dom";
import React, { useState } from 'react';
import Productpage from "../productpage/Component.js";
import Newcustomer from "./newcustomer/Component.js";
import Footer from "../../components/footer/Component.js";
import "./component.css";

const Component = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState("");

  const handleSelect = (floor) => {
    setSelectedFloor(floor);
    setIsOpen(false);
  };

  return (
    <>
      <Productpage />
      <div className="container my-top" id="checkout">
        <div className="table-responsive">
          <table className="table table-light my_table">
            <thead>
              <tr>
                <th>Room/Addition Services</th>
                <th>Checkin / Checkout</th>
                <th>Capacity</th>
                <th>Night</th>
                <th>Quantity</th>
                <th>Deposit Payment</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Lorem Ipsum is simply jnj</td>
                <td>May 29, 2024 - May 31, 2024</td>
                <td>1 adults,
                  0 childs</td>
                <td>9</td>
                <td>10</td>
                <td>Pending</td>
                <td>2000/-</td>
              </tr>

              <tr>
                <td>Lorem Ipsum is simply jnj</td>
                <td>May 29, 2024 - May 31, 2024</td>
                <td>1 adults,
                  0 childs</td>
                <td>9</td>
                <td>10</td>
                <td>Pending</td>
                <td>2000/-</td>
              </tr>

              <tr>
                <td>Lorem Ipsum is simply jnj</td>
                <td>May 29, 2024 - May 31, 2024</td>
                <td>1 adults,
                  0 childs</td>
                <td>9</td>
                <td>10</td>
                <td>Pending</td>
                <td>2000/-</td>
              </tr>
              <tr>
                <td>Lorem Ipsum is simply jnj</td>
                <td>May 29, 2024 - May 31, 2024</td>
                <td>1 adults,
                  0 childs</td>
                <td>9</td>
                <td>10</td>
                <td>Pending</td>
                <td>2000/-</td>
              </tr>
              <tr>
                <td colSpan={6}>Total</td>
                <td>92000/-</td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-4">

            <p className="Customer_head">
              Customer Details
            </p>
            <p className="Customer_desc">
              Existing Customer?
            </p>
            <form action="#">
              <div className="form-group checkout_flx">
                {/* <label for="pwd">Password:</label> */}
                <input type="text" className="form-control checkout_inp" id="pwd" placeholder="Enter Email" name="pswd" />
                <button type="submit" className="checkout_bot">Submit</button>
              </div>

            </form>
          </div>
          <div className="col-lg-8"></div>
        </div>
      </div>
      <Newcustomer />
      <Footer />
    </>

  );
};

export default Component;
