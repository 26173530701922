// import Topbar from "../components/topbar/Component.js";
import Nav from "../components/nav/Component.js";
import Featureone from "../components/featureone/Component.js";
// import Sliderone from "../components/sliderone/Component.js";
import Facilities from "../components/facilities/Component.js";
import Product from "../components/product/Component.js";
import Aboutus from "../components/aboutus/Component.js";
import Ourpics from "../components/ourpics/Component.js";
import Footer from "../components/footer/Component.js";
// import Sectionseven from "../components/sectionseven/Component.js";

import Floatbtn from "../components/floatbtn/Component.js";

import AOS from 'aos';

import "./../css/style.css";

const Component = () => {
  return (
    <>
    
      <Nav/>
      <Featureone/>
      <Facilities/>
      <Product/>
      <Aboutus/>
      <Ourpics/>
      <Footer/>
      <Floatbtn/>
    </>
  );
};

export default Component;
