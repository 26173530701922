import { Link } from "react-router-dom";
import React, { useState } from 'react';
import "./component.css";
import Tab from "./tab/Component.js";
import Similerproduct from "../../components/similerproduct/Component.js";
import Footer from "../../components/footer/Component.js";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Component = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const images = [
    'https://via.placeholder.com/600x400?text=Slide+1',
    'https://via.placeholder.com/600x400?text=Slide+2',
    'https://via.placeholder.com/600x400?text=Slide+3',
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState("");

  const handleSelect = (floor) => {
    setSelectedFloor(floor);
    setIsOpen(false);
  };
  const [activeTab, setActiveTab] = useState('home');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="my-top" id="features">

        <div className="single-color">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-8 more_img">
                <img src="img/ss1.png" className="img-fluid " alt="Clients" />
                <button class="moore-imgbot" data-bs-toggle="modal" data-bs-target="#myModal">More Images</button>

                {/* <!-- The Modal --> */}
                <div class="modal fade" id="myModal">
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">

                      {/* <!-- Modal Header --> */}
                      <div class="modal-header">
                        <h4 class="modal-title">Modal Heading</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                      </div>

                      {/* <!-- Modal body --> */}
                      <div class="modal-body">
                        <div className="carousel">
                          <Slider {...settings}>
                            {images.map((img, index) => (
                              <div key={index}>
                                <img src={img} alt={`Slide ${index + 1}`} />
                              </div>
                            ))}
                          </Slider>
                        </div>
                      </div>

                      {/* <!-- Modal footer --> */}
                      <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <img src="img/ss3.png" className="img-fluid pb-2" alt="Clients" />
                <img src="img/ss02.png" className="img-fluid " alt="Clients" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 ">
                <div className="location">
                  <div className="icon1">
                    <i className="ri-map-pin-line loc_ation"></i>
                  </div>
                  <div className="loc_desc">
                    <p className="mb-0 address">PLOT NO. 5B, SECTOR - 15A, FIRST FLOOR, NEELAM AJRONDA CHOWK METRO STATION, Faridabad, Haryana 121007</p>
                  </div>
                </div>
                <div className="location01">
                  <div className="single_detail">
                    <div className="icon1">
                      <i className="ri-drag-move-2-fill star_icn"></i>
                    </div>
                    <div className="loc_desc">
                      <p className="mb-0 address">4.5/5 (2 reviews)</p>
                    </div>
                  </div>
                  <div className="single_detail">
                    <div className="icon1">
                      <i className="ri-map-pin-line star_icn"></i>
                    </div>
                    <div className="loc_desc">
                      <p className="mb-0 address">4.5/5 (2 reviews)</p>
                    </div>
                  </div>
                  <div className="single_detail">
                    <div className="icon1">
                      <i className="ri-hotel-bed-line star_icn"></i>
                    </div>
                    <div className="loc_desc">
                      <p className="mb-0 address">4.5/5 (2 reviews)</p>
                    </div>
                  </div>
                  <div className="single_detail">
                    <div className="icon1">
                      <i className="ri-star-fill star_icn"></i>
                    </div>
                    <div className="loc_desc">
                      <p className="mb-0 address">4.5/5 (2 reviews)</p>
                    </div>
                  </div>
                </div>
                <div className="desc">
                  <h4>Description</h4>
                  <p className="details">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                </div>
                <div className="desc">
                  <h4>Room Amenities</h4>

                  <div className="row">
                    <div className="col-sm-4">
                      <div className=" single1_detail">
                        <div className="singleflx">
                          <i className="ri-map-pin-line single_icn"></i>
                        </div>
                        <div className="singleflx2">
                          <p className="mb-0 address">Accepts Credit Cards</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="single1_detail">
                        <div className="singleflx">
                          <i className="ri-hotel-bed-line single_icn"></i>
                        </div>
                        <div className="singleflx2">
                          <p className="mb-0 address">Car Parking</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 ">
                      <div className="single1_detail">
                        <div className="singleflx">
                          <i className="ri-star-fill single_icn"></i>
                        </div>
                        <div className="singleflx2">
                          <p className="mb-0 address">Laundry Service</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className=" single1_detail">
                        <div className="singleflx">
                          <i className="ri-map-pin-line single_icn"></i>
                        </div>
                        <div className="singleflx2">
                          <p className="mb-0 address">Accepts Credit Cards</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="single1_detail">
                        <div className="singleflx">
                          <i className="ri-hotel-bed-line single_icn"></i>
                        </div>
                        <div className="singleflx2">
                          <p className="mb-0 address">Car Parking</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 ">
                      <div className="single1_detail">
                        <div className="singleflx">
                          <i className="ri-star-fill single_icn"></i>
                        </div>
                        <div className="singleflx2">
                          <p className="mb-0 address">Laundry Service</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className=" single1_detail">
                        <div className="singleflx">
                          <i className="ri-map-pin-line single_icn"></i>
                        </div>
                        <div className="singleflx2">
                          <p className="mb-0 address">Accepts Credit Cards</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="single1_detail">
                        <div className="singleflx">
                          <i className="ri-hotel-bed-line single_icn"></i>
                        </div>
                        <div className="singleflx2">
                          <p className="mb-0 address">Car Parking</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 ">
                      <div className="single1_detail">
                        <div className="singleflx">
                          <i className="ri-star-fill single_icn"></i>
                        </div>
                        <div className="singleflx2">
                          <p className="mb-0 address">Laundry Service</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <br />
                <div className="desc">
                  <h4>Booking Rules</h4>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className=" single1_detail">
                        <div className="singleflx">
                          <i className="ri-map-pin-line single_icn"></i>
                        </div>
                        <div className="singleflx2">
                          <p className="mb-0 address">Accepts Credit Cards</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="single1_detail">
                        <div className="singleflx">
                          <i className="ri-hotel-bed-line single_icn"></i>
                        </div>
                        <div className="singleflx2">
                          <p className="mb-0 address">Car Parking</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 ">
                      <div className="single1_detail">
                        <div className="singleflx">
                          <i className="ri-star-fill single_icn"></i>
                        </div>
                        <div className="singleflx2">
                          <p className="mb-0 address">Laundry Service</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className=" single1_detail">
                        <div className="singleflx">
                          <i className="ri-map-pin-line single_icn"></i>
                        </div>
                        <div className="singleflx2">
                          <p className="mb-0 address">Accepts Credit Cards</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="single1_detail">
                        <div className="singleflx">
                          <i className="ri-hotel-bed-line single_icn"></i>
                        </div>
                        <div className="singleflx2">
                          <p className="mb-0 address">Car Parking</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 ">
                      <div className="single1_detail">
                        <div className="singleflx">
                          <i className="ri-star-fill single_icn"></i>
                        </div>
                        <div className="singleflx2">
                          <p className="mb-0 address">Laundry Service</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className=" single1_detail">
                        <div className="singleflx">
                          <i className="ri-map-pin-line single_icn"></i>
                        </div>
                        <div className="singleflx2">
                          <p className="mb-0 address">Accepts Credit Cards</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="single1_detail">
                        <div className="singleflx">
                          <i className="ri-hotel-bed-line single_icn"></i>
                        </div>
                        <div className="singleflx2">
                          <p className="mb-0 address">Car Parking</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 ">
                      <div className="single1_detail">
                        <div className="singleflx">
                          <i className="ri-star-fill single_icn"></i>
                        </div>
                        <div className="singleflx2">
                          <p className="mb-0 address">Laundry Service</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <Tab />
              </div>

              <div className="col-md-4 ">
                <div className="our_form singlep">
                  <div className="form_inner">
                    <h3>SERVICED APARTMENT</h3>
                    <form action="#">
                      <label for="pwd">Locatin:</label>
                      <div className={`custom-select ${isOpen ? 'open' : ''}`}>
                        <div className="form-group">
                          <div className="select-top" onClick={() => setIsOpen(!isOpen)}>
                            <span>{selectedFloor ? selectedFloor : 'Delhi'}</span>
                            <i class="ri-arrow-down-s-line"></i> {/* Font Awesome dropdown icon */}
                          </div>
                          <ul className="select-options">
                            <li onClick={() => handleSelect("Delhi")}>Delhi</li>
                            <li onClick={() => handleSelect("1stFloor")}>1st Floor</li>
                            <li onClick={() => handleSelect("2ndFloor")}>2nd Floor</li>
                            <li onClick={() => handleSelect("3rdFloor")}>3rd Floor</li>
                            <li onClick={() => handleSelect("4thFloor")}>4th Floor</li>
                            <li onClick={() => handleSelect("5thFloor")}>5th Floor</li>
                            <li onClick={() => handleSelect("6thFloor")}>6th Floor</li>
                            <li onClick={() => handleSelect("7thFloor")}>7th Floor</li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <label for="pwd">Check In</label>
                        <input type="date" className="form-control" placeholder="Enter Date" id="datein" />
                      </div>
                      <div className="form-group">
                        <label for="pwd">Check Out</label>
                        <input type="date" className="form-control" placeholder="Enter Date" id="dateout" />
                      </div>
                      <div className="form-group">
                        <label for="pwd">Guest</label>
                        <input type="text" className="form-control" placeholder="Enter Date" id="guest" />
                      </div>
                      <Link to="/checkout" className="dop"> <button type="submit" className="srarch_bot">Submit</button></Link>
                    </form>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

        <Similerproduct />
        <Footer />
      </div>
      {/* <!-- The Modal --> */}



    </>

  );
};

export default Component;
