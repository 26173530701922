import { React } from "react";
import ReactDOM from "react-dom/client";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Home from "./website/screens/Home.js";
import Login from "./website/screens/Login.js";
import Signup from "./website/screens/Signup.js";
import Checkout from "./website/screens/Checkout.js";
import Order from "./website/screens/Order.js";
import Aboutus from "./website/screens/Aboutus.js";
import Contact from "./website/screens/Contact.js";
import Privacy from "./website/screens/Privacy.js";
import Product from "./website/screens/Product.js";
import Singleproduct from "./website/screens/Singleproduct.js";
import Terms from "./website/screens/Terms.js";
import ScrollToTop from './website/screens/ScrollToTop.js';
import Blog from "./website/screens/Blog.js";
import InnerBlog from "./website/screens/Innerblog.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

function App() {

  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route exact path='/' element={<Home />} />
          {/* <Route path='/*' element={<ClientRoutes />} /> */}
          {/* <Route exact path='/login' element={<Login />} /> */}
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/product' element={<Product />} />
          <Route path='/Singleproduct' element={<Singleproduct />} />
          <Route path='/terms' element={<Terms />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/signup' element={<Signup />} />
          <Route exact path='/checkout' element={<Checkout />} />
          <Route exact path='/order' element={<Order />} />
          <Route exact path='/aboutus' element={<Aboutus />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/blog' element={<Blog />} />
          <Route exact path='/innerblog' element={<InnerBlog />} />
          {/* <Route path='/hotel/:hotelId/backend/*' element={<HotelsRoutes />} /> */}
          {/* <Route path='Br!Y7o&p45fM/portal/*' element={<AdminRoute />} /> */}
          {/* Private routes hotel panel */}
          {/* <Route path='/hotel' element={<PrivateRoutes />}> */}
          {/* </Route> */}
        </Routes>
      </Router>
    </>
  );
}

root.render(<App />);
