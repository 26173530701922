import { Link } from "react-router-dom";
import React, { useState } from 'react';
import "./component.css";

const Component = () => {
  return (
    <>
      <div className="my-top" id="features">

        <div className="footer">
          <div className="container ">

            <div className="my_newsletter">
              <div className="row">
                <div className="col-md-9 foot">
                  <p class="txt">Subscribe Newsletter</p>
                  <p className="foot-txt2">The Travel</p>
                  <p className="foot-txt3">Get inspired! Receive travel discounts, tips and behind the scenes stories.</p>
                  <div className="form1">
                    <div className="form-group form_box">

                      <input type="email" class="form-control input_feild" placeholder="Your E-mail Address" id="pwd" />
                      <button type="submit" class="btn btn-primary submit_bot">Subscribe</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <img src="img/newsletter.png" className="img-fluid footer_image" alt="Clients" />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid footer1">
            <div className="row inner_footer">
              <div className="col-xl-1"></div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                <h3 className="logo_txt">Logo</h3>
                <p>Boliin is the Best Resort & Hotel Booking WordPress Theme in the Market. Lacinia erat vestibioas beauty products.</p>
                <div className="socila_media">
                  <i className="ri-instagram-line"></i>
                  <i className="ri-facebook-circle-fill"></i>
                  <i className="ri-twitter-line"></i>
                  <i className="ri-linkedin-line"></i>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-6 col-6 ab">

                <h4>Links</h4>

                <p className="mt-4">Hotel Rooms</p>
                <p>Restaurant</p>
                <p>Privacy policy</p>
                <p><Link to="/contact" className="dop2">Contact us</Link></p>
                <p><Link to="/aboutus" className="dop2">About us</Link></p>

              </div>
              <div className="col-xl-2 col-lg-3 col-md-6 col-6 ab">
                <h4>Links</h4>
                <p className="mt-4"> <Link to="/contact" className="dop2">Our Location</Link></p>
                <p>Career</p>
                <p>Customer support</p>
                <p><Link to="/blog" className="dop2"> Blog</Link></p>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-12 ab">
                <h4>Contact</h4>
                <p className="mt-4">support@example.com 972 Westheimer Santa Ana,</p>
                <p>Illinois, USA</p>
                <p>1-800-123-4567</p>
              </div>
              <div className="col-xl-1 "></div>
            </div>
          </div>
        </div>
      </div>

    </>

  );
};

export default Component;
