import { Link } from "react-router-dom";
import React, { useState } from 'react';
import Productpage from "../productpage/Component.js";

import Footer from "../footer/Component.js";
import "./component.css";

const Component = () => {

  return (
    <>
      <Productpage />

      <div className="container blog-top">
        <div className="row">
          <div className="col-lg-8 col-md-7">
            <div className="blog-card">
              
              <div className="b-inner-txt">
                <p className="inn_txt">How We Got Started</p>
                <div className="blog-date">
                  <div className=" b-single1_detail">
                    <div className="singleflx">
                      {/* <i className="ri-map-pin-line "></i> */}
                      <i className="ri-home-heart-line b-single_icn"></i>
                    </div>
                    <div className="singleflx2">
                      <p className="mb-0 address">24 April 2024</p>
                    </div>
                  </div>
                  <div className=" b-single1_detail">
                    <div className="singleflx">
                      {/* <i className="ri-map-pin-line "></i> */}
                      <i className="ri-home-heart-line b-single_icn"></i>
                    </div>
                    <div className="singleflx2">
                      <p className="mb-0 address">Admin</p>
                    </div>
                  </div>
                  <div className=" b-single1_detail">
                    <div className="singleflx">
                      {/* <i className="ri-map-pin-line "></i> */}
                      <i className="ri-home-heart-line b-single_icn"></i>
                    </div>
                    <div className="singleflx2">
                      <p className="mb-0 address">Comment Off</p>
                    </div>
                  </div>
                </div>
                <p className="blog-txt1">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                </p>
                
              </div>
              <img src="img/abt1.png" className="img-fluid" />
              <div className="b-inner-txt">
               
                <p className="blog-txt1">
                A sofa set is an essential piece of furniture that combines comfort with style, making it the focal point of any living room. It provides a cozy space for relaxing, entertaining guests, or enjoying time with family. There are various types of sofa sets, including sectional sofas that can be arranged in different configurations, loveseat sofas for smaller spaces, and sofa cum beds that offer the versatility of a bed when needed. Recliner sofas are designed for ultimate comfort, with adjustable backrests and footrests, perfect for relaxation or movie nights. L-shaped sofas maximize seating in corner spaces without overwhelming the room.
                </p>
                <p className="blog-txt1">
                Sofa sets come in different materials to suit your needs and preferences. Leather offers a sleek, modern look and is easy to clean, making it ideal for a sophisticated setting. Fabric sofas are soft and comfortable, available in numerous colors and patterns, while velvet adds a touch of luxury and elegance. For durability and stain resistance, microfiber is a popular option, especially for homes with children or pets. When choosing a sofa set, it’s important to consider the size, ensuring it fits well within your space without overpowering the room.
                </p>
                <p className="blog-txt1">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                </p>
                <p className="blog-txt1">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                </p>
               
                
              </div>
            </div>
           
          </div>
          <div className="col-lg-4 col-md-5">
            <div className="blog-category">
              <p className="blog-category-head">
                Category
              </p>
              <div className=" cate-single1_detail">
                <div className="singleflx">
                  <i className="ri-bard-line cate-single_icn"></i>
                </div>
                <div className="singleflx2">
                  <p className="mb-0 address-cate">Car lifestyle (3)</p>
                </div>
              </div>
              <div className=" cate-single1_detail">
                <div className="singleflx">
                  <i className="ri-bard-line cate-single_icn"></i>
                </div>
                <div className="singleflx2">
                  <p className="mb-0 address-cate">House</p>
                </div>
              </div>
              <div className=" cate-single1_detail">
                <div className="singleflx">
                  <i className="ri-bard-line cate-single_icn"></i>
                </div>
                <div className="singleflx2">
                  <p className="mb-0 address-cate">Machine & cars</p>
                </div>
              </div>
              <div className=" cate-single1_detail">
                <div className="singleflx">
                  <i className="ri-bard-line cate-single_icn"></i>
                </div>
                <div className="singleflx2">
                  <p className="mb-0 address-cate">Car lifestyle (3)</p>
                </div>
              </div>
              <div className=" cate-single1_detail">
                <div className="singleflx">
                  <i className="ri-bard-line cate-single_icn"></i>
                </div>
                <div className="singleflx2">
                  <p className="mb-0 address-cate">House</p>
                </div>
              </div>
              <div className=" cate-single1_detail">
                <div className="singleflx">
                  <i className="ri-bard-line cate-single_icn"></i>
                </div>
                <div className="singleflx2">
                  <p className="mb-0 address-cate">Machine & cars</p>
                </div>
              </div>
              <p className="blog-tag-head">
                Tags
              </p>
              <div className="tags-blog">
                <button className="tag-bot1">Boom Truck</button>
                <button className="tag-bot1">Truck</button>
                <button className="tag-bot1">Car</button>
                <button className="tag-bot1">Drive</button>
                <button className="tag-bot1">School</button>
                <button className="tag-bot1">News</button>
                <button className="tag-bot1">Hospital</button>
                <button className="tag-bot1">Boom Truck</button>
                <button className="tag-bot1">Truck</button>
                <button className="tag-bot1">Car</button>
                <button className="tag-bot1">Drive</button>
                <button className="tag-bot1">School</button>
                <button className="tag-bot1">News</button>
                <button className="tag-bot1">Hospital</button>
                <button className="tag-bot1">Games</button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>

  );
};

export default Component;
