import React, { useState } from 'react';
import './component.css';
import { Link } from "react-router-dom";

const Component = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState("");

  const handleSelect = (floor) => {
    setSelectedFloor(floor);
    setIsOpen(false);
  };

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [isListView, setIsListView] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const showList = (e) => {
    e.preventDefault();
    setIsListView(true);
  };

  const showGrid = (e) => {
    e.preventDefault();
    setIsListView(false);
  };

  const accordionContent = {
    Accessories: (
      <>
        <p className="arow-flx"><i className="ri-arrow-drop-right-line"></i> &nbsp;&nbsp;Stationery</p>
        <p className="arow-flx"><i className="ri-arrow-drop-right-line"></i> &nbsp;&nbsp;Accessories</p>
        <p className="arow-flx"><i className="ri-arrow-drop-right-line"></i> &nbsp;&nbsp;Dresses</p>
        <p className="arow-flx"><i className="ri-arrow-drop-right-line"></i> &nbsp;&nbsp;Lifestyle</p>
      </>
    ),
    Featured: (
      <>
        <p className="arow-flx"><i className="ri-arrow-drop-right-line"></i> &nbsp;&nbsp;Stationery</p>
        <p className="arow-flx"><i className="ri-arrow-drop-right-line"></i> &nbsp;&nbsp;Accessories</p>
        <p className="arow-flx"><i className="ri-arrow-drop-right-line"></i> &nbsp;&nbsp;Dresses</p>
        <p className="arow-flx"><i className="ri-arrow-drop-right-line"></i> &nbsp;&nbsp;Lifestyle</p>
      </>
    ),
    Home1: (
      <>
        <p className="arow-flx"><i className="ri-arrow-drop-right-line"></i> &nbsp;&nbsp;Living Room</p>
        <p className="arow-flx"><i className="ri-arrow-drop-right-line"></i> &nbsp;&nbsp;Bed Room</p>
        <p className="arow-flx"><i className="ri-arrow-drop-right-line"></i> &nbsp;&nbsp;SofaLady Chair</p>
        <p className="arow-flx"><i className="ri-arrow-drop-right-line"></i> &nbsp;&nbsp;Chair</p>
        <p className="arow-flx"><i className="ri-arrow-drop-right-line"></i> &nbsp;&nbsp;DesignLamp</p>
      </>
    ),
    Size: (
      <>
        <label className="container12">S(2)
          <input type="checkbox" checked="checked" />
          <span className="checkmark"></span>
        </label>
        <label className="container12">M(3)
          <input type="checkbox" />
          <span className="checkmark"></span>
        </label>
        <label className="container12">L(4)
          <input type="checkbox" />
          <span className="checkmark"></span>
        </label>
        <label className="container12">XL(1)
          <input type="checkbox" />
          <span className="checkmark"></span>
        </label>
        <label className="container12">XXL(11)
          <input type="checkbox" />
          <span className="checkmark"></span>
        </label>
      </>
    ),
  };

  return (
    <>
      <div className="container">
        <div className="row cont-top">
          <div className="col-sm-9 col-5">
            <div id="mySidenav" className={`sidenav ${isNavOpen ? 'open' : ''}`}>
              <span className="closebtn" onClick={toggleNav}>&times;</span>
              <div className="row">
                <div className="col-sm-12">
                  <div className="accordion">
                    {['Accessories', 'Featured', 'Home1', 'Size'].map((section, index) => (
                      <div
                        className={`content-container ${activeAccordion === index ? 'active' : ''}`}
                        key={index}
                        onClick={() => toggleAccordion(index)}
                      >
                        <div className="question">{section}</div>
                        <div className="answer">
                          {activeAccordion === index && accordionContent[section]}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="clor-devision">
                    <p className="sofa-xt1">Color</p>
                    {/* Add color options here */}
                  </div>

                </div>
              </div>
            </div>
            <p className="arow-flx">
              <span className="arow-flx" style={{ fontSize: '21px', color: '#585656c7', cursor: 'pointer' }} onClick={toggleNav}>
                <i className="ri-equalizer-line"></i>
                <label style={{ marginTop: '6px', fontSize: '21px', color: '#585656c7', fontWeight: '500' }}>&nbsp; Filters</label>
              </span>
            </p>
          </div>
          <div className="col-sm-3 col-7">

            <div className={`custom-select ${isOpen ? 'open' : ''}`}>
              <div className="form-group m_seect">
                <div className="select-top" onClick={() => setIsOpen(!isOpen)}>
                  <span>{selectedFloor ? selectedFloor : 'Sort By'}</span>
                  <i class="ri-arrow-down-s-line"></i> {/* Font Awesome dropdown icon */}
                </div>
                <ul className="select-options">
                  <li onClick={() => handleSelect("Name A to Z")}>Name A to Z</li>
                  <li onClick={() => handleSelect("Name a to z")}>Name a to z</li>
                  <li onClick={() => handleSelect("Price, Low To High")}>Price, Low To High</li>
                  <li onClick={() => handleSelect("Price, High To Low")}>Price, High To Low</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className=" stay_grid">
          <div className="abc">
            <button className="grd-bot btn-grid" onClick={showGrid}>
              <i className="ri-grid-fill"></i> &nbsp; &nbsp;Grid View
            </button>
          </div>
          <div className="cdf">
            <button className="grd-bot btn-list" onClick={showList}>
              <i className="ri-align-justify"></i> &nbsp; &nbsp; List View
            </button>
          </div>
        </div>
      </div>

      <div className={`container-fluid product_side1 grid-container ${isListView ? 'list-view' : ''}`}>
        <div className="row">
          {[
            { src: 'img/product04.png', title: 'Stanhope room', price: '₹1,000/- ' },
            { src: 'img/product01.png', title: 'Double Room 5', price: '₹ 40,000/-' },
            { src: 'img/product03.png', title: 'Wood King Si...', price: '₹ 90,000/-' },
            { src: 'img/product02.png', title: 'Double Room 5', price: '₹ 10,000/-' },
            { src: 'img/product01.png', title: 'Stanhope room ', price: '₹ 1,000/-' },
            { src: 'img/product03.png', title: 'Double Room 5', price: '₹ 90,000/-' },
            { src: 'img/product04.png', title: 'Stanhope room', price: '₹1,000/- ' },
            { src: 'img/product01.png', title: 'Double Room 5', price: '₹ 40,000/-' },
            { src: 'img/product03.png', title: 'Wood King Si...', price: '₹ 90,000/-' },
            { src: 'img/product02.png', title: 'Double Room 5', price: '₹ 10,000/-' },
            { src: 'img/product01.png', title: 'Stanhope room ', price: '₹ 1,000/-' },
            { src: 'img/product03.png', title: 'Double Room 5', price: '₹ 90,000/-' },
          ].map((product, index) => (
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" key={index}>
              <div className="card product_card">
                <div className='main_relative'>
                  <img src={product.src} className="img-responsive card-img-top" alt={product.title} />
                  <div className='feature_bot'>
                    <div className='feature_bot1'>
                      featured
                    </div>
                    <div className='feature_bot2'>
                      10% off
                    </div>

                  </div>
                </div>

                <div className="card-body product_text">
                  <h5 className="product_head">{product.title}</h5>
                  {[
                    { subtitle: '4.5/5 (2 reviews)', icon: 'ri-star-fill star_icn' },
                    { subtitle: 'Room size:50sqm', icon: 'ri-drag-move-2-fill star_icn' },
                    { subtitle: '3 bhk', icon: 'ri-home-heart-line star_icn' },
                    { subtitle: 'Bed: 1bed', icon: 'ri-hotel-bed-line star_icn' },
                    // {subtitle: '4.5/5 (2 reviews)', icon: 'ri-star-fill star_icn'},
                  ].map((product1, index) => (
                    <div className='subtitle_flx' key={index}>
                      <div className='subtitle_flx1'>
                        <i className={product1.icon}></i>
                      </div>
                      <div className='subtitle_flx2'>
                        <p className="product_txt1 m-0">{product1.subtitle}</p>
                      </div>

                    </div>
                  ))}
                  <hr className="line2" />
                  {/* <p className="product_txt1 mb-1">{product.price}</p> */}
                  {/* <button className="bot01">View details&nbsp;&nbsp; <i className="fa fa-angle-double-right"></i></button> */}

                  <div class="price">
                    <div class="price_icn">
                      <p class="product_txt1 m-0">{product.price}</p>
                    </div>
                    <div class="price_txt">
                      <Link to="/singleproduct"> <p class="product_txt1 m-0">View Details</p></Link>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='page_block'>
        <ul className="pagination">
          <li className="page-item"><a class="page-link" href="#"><i className="ri-arrow-left-s-line"></i></a></li>
          <li className="page-item"><a class="page-link" href="#">1</a></li>
          <li className="page-item active"><a class="page-link" href="#">2</a></li>
          <li className="page-item"><a class="page-link" href="#">3</a></li>
          <li className="page-item"><a class="page-link" href="#"><i className="ri-arrow-right-s-line"></i></a></li>
        </ul>
      </div>
    </>
  );
};

export default Component;
