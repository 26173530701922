import { Helmet } from "react-helmet";
import Nav from "../components/nav/Component.js";
import Productpage from "../components/productpage/Component.js";
import Filter from "../components/myproducts/filter/Component.js";

import Floatbtn from "../components/floatbtn/Component.js";
import Footer from "../components/footer/Component.js";

import "./../css/style.css";

const Component = () => {
  return (
    <>
      <Helmet>
        <title>Products</title>
      </Helmet>
      <Nav />
      <Productpage />
      <Filter />
      <Footer />
      <Floatbtn />
    </>
  );
};

export default Component;
