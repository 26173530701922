import { Link } from "react-router-dom";
import React, { useState } from 'react';
import "./component.css";

const Component = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState("");

  const handleSelect = (floor) => {
    setSelectedFloor(floor);
    setIsOpen(false);
  };

  return (
    <>
      <div className="my-top" id="features">
        <center>
          <p className="stay-theam">Happy To Stay</p>
          <hr class="stay-line" />
        </center>
        <div className="product_side">
          <div className="container-fluid">
            <div className="row ">
              <img src="img/ourpics.png" className="img-fluid about" alt="No-image" /> 
              
            </div>
          </div>
        </div>
      </div>

    </>

  );
};

export default Component;
