import { Link } from "react-router-dom";
import React, { useState } from 'react';
import Productpage from "../productpage/Component.js";

import Footer from "../footer/Component.js";
import "./component.css";

const Component = () => {

  return (
    <>
      <Productpage />

      <div className="container blog-top">
        <div className="row">
          <div className="col-lg-8 col-md-7">
            <div className="blog-card">
              <img src="img/ss1.png" className="img-fluid b-log_image" />
              <div className="b-inner-txt">
                <p className="inn_txt">How We Got Started</p>
                <div className="blog-date">
                  <div className=" b-single1_detail">
                    <div className="singleflx">
                      {/* <i className="ri-map-pin-line "></i> */}
                      <i className="ri-home-heart-line b-single_icn"></i>
                    </div>
                    <div className="singleflx2">
                      <p className="mb-0 address">24 April 2024</p>
                    </div>
                  </div>
                  <div className=" b-single1_detail">
                    <div className="singleflx">
                      {/* <i className="ri-map-pin-line "></i> */}
                      <i className="ri-home-heart-line b-single_icn"></i>
                    </div>
                    <div className="singleflx2">
                      <p className="mb-0 address">Admin</p>
                    </div>
                  </div>
                  <div className=" b-single1_detail">
                    <div className="singleflx">
                      {/* <i className="ri-map-pin-line "></i> */}
                      <i className="ri-home-heart-line b-single_icn"></i>
                    </div>
                    <div className="singleflx2">
                      <p className="mb-0 address">Comment Off</p>
                    </div>
                  </div>
                </div>
                <p className="blog-txt1">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                </p>
                <p className="blog-read">
                 <Link to="/innerblog"> Read more</Link>
                </p>
              </div>
            </div>
            
            <div className="blog-card">
              <img src="img/abt1.png" className="img-fluid b-log_image" />
              <div className="b-inner-txt">
                <p className="inn_txt">How We Got Started</p>
                <div className="blog-date">
                  <div className=" b-single1_detail">
                    <div className="singleflx">
                      {/* <i className="ri-map-pin-line "></i> */}
                      <i className="ri-home-heart-line b-single_icn"></i>
                    </div>
                    <div className="singleflx2">
                      <p className="mb-0 address">24 April 2024</p>
                    </div>
                  </div>
                  <div className=" b-single1_detail">
                    <div className="singleflx">
                      {/* <i className="ri-map-pin-line "></i> */}
                      <i className="ri-home-heart-line b-single_icn"></i>
                    </div>
                    <div className="singleflx2">
                      <p className="mb-0 address">Admin</p>
                    </div>
                  </div>
                  <div className=" b-single1_detail">
                    <div className="singleflx">
                      {/* <i className="ri-map-pin-line "></i> */}
                      <i className="ri-home-heart-line b-single_icn"></i>
                    </div>
                    <div className="singleflx2">
                      <p className="mb-0 address">Comment Off</p>
                    </div>
                  </div>
                </div>
                <p className="blog-txt1">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                </p>
                <p className="blog-read">
                 <Link to="/innerblog"> Read more</Link>
                </p>
              </div>
            </div>
            
            <div className="blog-card">
              <img src="img/abt2.png" className="img-fluid b-log_image" />
              <div className="b-inner-txt">
                <p className="inn_txt">How We Got Started</p>
                <div className="blog-date">
                  <div className=" b-single1_detail">
                    <div className="singleflx">
                      {/* <i className="ri-map-pin-line "></i> */}
                      <i className="ri-home-heart-line b-single_icn"></i>
                    </div>
                    <div className="singleflx2">
                      <p className="mb-0 address">24 April 2024</p>
                    </div>
                  </div>
                  <div className=" b-single1_detail">
                    <div className="singleflx">
                      {/* <i className="ri-map-pin-line "></i> */}
                      <i className="ri-home-heart-line b-single_icn"></i>
                    </div>
                    <div className="singleflx2">
                      <p className="mb-0 address">Admin</p>
                    </div>
                  </div>
                  <div className=" b-single1_detail">
                    <div className="singleflx">
                      {/* <i className="ri-map-pin-line "></i> */}
                      <i className="ri-home-heart-line b-single_icn"></i>
                    </div>
                    <div className="singleflx2">
                      <p className="mb-0 address">Comment Off</p>
                    </div>
                  </div>
                </div>
                <p className="blog-txt1">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                </p>
                <p className="blog-read">
                 <Link to="/innerblog"> Read more</Link>
                </p>
              </div>
            </div>
            
          </div>
          <div className="col-lg-4 col-md-5">
            <div className="blog-category">
              <p className="blog-category-head">
                Category
              </p>
              <div className=" cate-single1_detail">
                <div className="singleflx">
                  <i className="ri-bard-line cate-single_icn"></i>
                </div>
                <div className="singleflx2">
                  <p className="mb-0 address-cate">Car lifestyle (3)</p>
                </div>
              </div>
              <div className=" cate-single1_detail">
                <div className="singleflx">
                  <i className="ri-bard-line cate-single_icn"></i>
                </div>
                <div className="singleflx2">
                  <p className="mb-0 address-cate">House</p>
                </div>
              </div>
              <div className=" cate-single1_detail">
                <div className="singleflx">
                  <i className="ri-bard-line cate-single_icn"></i>
                </div>
                <div className="singleflx2">
                  <p className="mb-0 address-cate">Machine & cars</p>
                </div>
              </div>
              <div className=" cate-single1_detail">
                <div className="singleflx">
                  <i className="ri-bard-line cate-single_icn"></i>
                </div>
                <div className="singleflx2">
                  <p className="mb-0 address-cate">Car lifestyle (3)</p>
                </div>
              </div>
              <div className=" cate-single1_detail">
                <div className="singleflx">
                  <i className="ri-bard-line cate-single_icn"></i>
                </div>
                <div className="singleflx2">
                  <p className="mb-0 address-cate">House</p>
                </div>
              </div>
              <div className=" cate-single1_detail">
                <div className="singleflx">
                  <i className="ri-bard-line cate-single_icn"></i>
                </div>
                <div className="singleflx2">
                  <p className="mb-0 address-cate">Machine & cars</p>
                </div>
              </div>
              <p className="blog-tag-head">
                Tags
              </p>
              <div className="tags-blog">
                <button className="tag-bot1">Boom Truck</button>
                <button className="tag-bot1">Truck</button>
                <button className="tag-bot1">Car</button>
                <button className="tag-bot1">Drive</button>
                <button className="tag-bot1">School</button>
                <button className="tag-bot1">News</button>
                <button className="tag-bot1">Hospital</button>
                <button className="tag-bot1">Boom Truck</button>
                <button className="tag-bot1">Truck</button>
                <button className="tag-bot1">Car</button>
                <button className="tag-bot1">Drive</button>
                <button className="tag-bot1">School</button>
                <button className="tag-bot1">News</button>
                <button className="tag-bot1">Hospital</button>
                <button className="tag-bot1">Games</button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>

  );
};

export default Component;
