import Nav from "../components/nav/Component.js";
import Singleproduct from "../components/singleproduct/Component.js";
import Footer from "../components/footer/Component.js";
// import Sectionseven from "../components/sectionseven/Component.js";
import Floatbtn from "../components/floatbtn/Component.js";

import AOS from 'aos';

import "./../css/style.css";

const Component = () => {
  return (
    <>
    
      <Nav/>
      
      <Singleproduct/>
      {/* <Footer/> */}
      <Floatbtn/>
    </>
  );
};

export default Component;
