import { Link } from "react-router-dom";
import React, { useState } from 'react';
import "./component.css";
import Team from "./team.js";
import Footer from "../footer/Component.js";

const Component = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState("");

  const handleSelect = (floor) => {
    setSelectedFloor(floor);
    setIsOpen(false);
  };

  return (
    <>
      <div className="about-my-top" id="features">
        <div class="container">
          <h3 className="about-head1">Building The Future Of Hospitality</h3>
          <p className="about-desc1">Hotels aren't always hip. Hosts aren't always reliable. So we're changing the game—with spaces that inspire and delight, that fulfill needs without sacrificing style, all while delivering seamless, personalized experiences. Check in, request fresh towels, and get dinner recommendations—all from your phone.</p>
          <p className="about-desc1">Hotels aren't always hip. Hosts aren't always reliable. So we're changing the game—with spaces that inspire and delight, that fulfill needs without sacrificing style, all
          </p>
          <div className="row my_flex2">
            <div className="col-sm-6">
              <center><img src="img/abt1.png" className="img-fluid pre_sec1" /></center>
            </div>
            <div className="col-sm-6 innfinder">
              <p className="inn_txt">How We Got Started</p>
              <p className="inn_txt2">Hotels aren’t always hip. Hosts aren’t always reliable. So we’re changing the game—with spaces that inspire and delight, that fulfill needs without sacrificing style, all while delivering seamless, personalized experiences. Check in, request fresh towels, and get dinner recommendations—all from your phone.</p>
              {/* <button className="yog_bot" data-bs-toggle="modal" data-bs-target="#free_consult">Free Consultation</button> */}
            </div>
          </div>
          <div className="row my_flex3">
            <div className="col-sm-6 innfinder">
              <p className="inn_txt">How We Got Started</p>
              <p className="inn_txt2">Hotels aren’t always hip. Hosts aren’t always reliable. So we’re changing the game—with spaces that inspire and delight, that fulfill needs without sacrificing style, all while delivering seamless, personalized experiences. Check in, request fresh towels, and get dinner recommendations—all from your phone.</p>
              {/* <button className="yog_bot" data-bs-toggle="modal" data-bs-target="#free_consult">Free Consultation</button> */}
            </div>
            <div className="col-sm-6">
              <center><img src="img/abt2.png" className="img-fluid pre_sec1" /></center>
            </div>
          </div>
        </div>
        <div class="youtube">
          <video width="100%" height="auto" autoPlay muted loop controls={false} style={{ WebkitTransform: 'translateZ(0)', transform: 'translateZ(0)' }} id="myVideo">
            <source src="img/aboutus.mp4" type="video/mp4" className="img-fluid" />
          </video>
        </div>
        <Team />
        <Footer />
      </div>
    </>
  );
};

export default Component;
