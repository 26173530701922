import { Link } from "react-router-dom";
import React, { useState } from 'react';
import "./component.css";

const Component = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState("");

  const handleSelect = (floor) => {
    setSelectedFloor(floor);
    setIsOpen(false);
  };

  return (
    <>
      <div className="back1" id="features">
        <div className="row">
          <div className="login-form">
            <div className="col-md-5 col-11 shadow-lg innerform">
              <p className="log_in-heading">Log In</p>
              <form action="#">
                <div className="form-group">
                  <label for="email">Email:</label>
                  <input type="Text" className="form-control" id="email" placeholder="Enter email" name="email"/>
                </div>
                <div className="form-group">
                  <label for="pwd">Password:</label>
                  <input type="password" className="form-control" id="pwd" placeholder="Enter password" name="pswd"/>
                </div>
                {/* <div className="form-group form-check">
                  <label className="form-check-label">
                    <input className="form-check-input" type="checkbox" name="remember"/> Remember me
                  </label>
                </div> */}
                <div className="forgot">
                  <p className="pass_word">Don't have an account <Link to="/signup"className="dop"><span className="signup"> Sign Up</span></Link></p>
                  <p className="pass_word">Forgot Password ?</p>
                </div>
                <button type="submit" className="go_submit">Log in</button>
              </form>
            </div>
          </div>
        </div>
        <div className="foot_rightreserved">
          <p className="foot_desc">@2024 All rights reserved </p>
        </div>
      </div>

    </>

  );
};

export default Component;
