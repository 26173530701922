import { Link } from "react-router-dom";
import React, { useState } from 'react';
import "./component.css";

const Component = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState("");

  const handleSelect = (floor) => {
    setSelectedFloor(floor);
    setIsOpen(false);
  };

  return (
    <>
      <div className="container-fluid team">
        <div className="row">
          <h2 className="team-head">Expart Management Team</h2>
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <div className="team-card">
            <img src="img/p1.png" className="img-fluid"/>
              <p className="founder">Founder</p>
              <p className="founder-name">Mr Sumit Sharma</p>

          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <div className="team-card">
            <img src="img/p2.png" className="img-fluid"/>
              <p className="founder">Founder</p>
              <p className="founder-name">Mr Sumit Sharma</p>

          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <div className="team-card">
            <img src="img/p3.png" className="img-fluid"/>
              <p className="founder">Founder</p>
              <p className="founder-name">Mr Sumit Sharma</p>

          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <div className="team-card">
            <img src="img/p4.png" className="img-fluid"/>
              <p className="founder">Founder</p>
              <p className="founder-name">Mr Sumit Sharma</p>

          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default Component;
