import { Link } from "react-router-dom";
import React, { useState } from 'react';
const Component = () => {


  return (
    <>
      <div className="container customer_top">
        <p className="Customer_head">
          Customer Details
        </p>
        <div className="row roww_top">

          <div className="col-lg-6">
            <div className="form-group">
              <label for="name">Name</label>
              <input type="text" className="form-control" id="name" placeholder="Enter Name" name="name" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label for="Last">Last Name</label>
              <input type="text" className="form-control" id="Last" placeholder="Enter Last Name" name="Last_Name" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label for="Email">Email Address</label>
              <input type="email" className="form-control" id="Email" placeholder="Enter Email" name="email" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label for="Number">Phone Number</label>
              <input type="number" className="form-control" id="Number" placeholder="Enter Phone Number" name="number" />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label for="Country">Country</label>
              {/* <input type="text"  id="Country" placeholder="Enter Country" name="Country"/> */}
              <select className="form-select">
                <option>India</option>
                <option>India</option>
                <option>India</option>
                <option>India</option>
                <option>India</option>
              </select>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label for="State">State</label>
              <input type="text" className="form-control" id="State" placeholder="Enter State" name="State" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label for="Town">Town</label>
              <input type="Town" className="form-control" id="Town" placeholder="Enter Town" name="Town" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label for="Street">Street</label>
              <input type="email" className="form-control" id="Street" placeholder="Enter Street" name="Street" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label for="code">Pin code</label>
              <input type="Text" className="form-control" id="code" placeholder="Enter Pin code" name="code" />
            </div>
          </div>
          <p className="Customer_opt">
            Choose Payment Opetion
          </p>
          <div className="col-lg-12">
            <div className="radio_form">
              <input type="radio" id="Credit" name="fav_language" value="HTML" />
              <label for="Credit">Credit Card</label><br />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="radio_form">
              <input type="radio" id="Debit" name="fav_language" value="HTML" />
              <label for="Debit">Debit Card</label><br />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="radio_form">
              <input type="radio" id="Paytm" name="fav_language" value="HTML" />
              <label for="Paytm">Paytm</label><br />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="radio_form">
              <input type="radio" id="Google" name="fav_language" value="HTML" />
              <label for="Google">Google Pay</label><br />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="radio_form">
              <input type="radio" id="Phone" name="fav_language" value="HTML" />
              <label for="Phone">Phone Pay</label><br />
            </div>
          </div>
          <Link to="/order" className="dop"> <button type="submit" class="srarch_bot">Submit</button></Link>

        </div>
      </div>
    </>

  );
};

export default Component;
