import { Link } from "react-router-dom";
import React, { useState } from 'react';
import "./component.css";

const Component = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState("");

  const handleSelect = (floor) => {
    setSelectedFloor(floor);
    setIsOpen(false);
  };

  return (
    <>
      <div className="my-top " id="features">
        <div className="container-fluid over1">
          <div className="row header_backcolor">
            <div className="col-lg-5 col-md-7">
              <div className="hut_new">
                <div className="hut-imagek">
                  <p className="hut-txt ">The Great Place</p>
                  <p className="hut-txt2">HOTEL  MASTER'S<br></br>  ROOM AVAILABLE</p>
                  <p className="hut-txt-desc mb-0">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make f type and scramb</p>
                </div>
              </div>
            
            </div>
            <div className="col-lg-7 col-md-5">
            </div>
          </div>
          <div className="row stay-flax">
            <div className="col-xl-12">
              <div className="form_fealds">
                {/* <img src="img/apartment.png" className="img-fluid hut-image2" alt="Clients" /> */}
                <div className="form_inner">
                  <h3>SERVICED APARTMENT</h3>
                  <form action="#">
                      <label for="pwd">Locatin:</label>
                    <div className={`custom-select ${isOpen ? 'open' : ''}`}>
                      <div className="form-group">
                        <div className="select-top" onClick={() => setIsOpen(!isOpen)}>
                          <span>{selectedFloor ? selectedFloor : 'Delhi'}</span>
                          <i class="ri-arrow-down-s-line"></i> {/* Font Awesome dropdown icon */}
                        </div>
                        <ul className="select-options">
                          <li onClick={() => handleSelect("Delhi")}>Delhi</li>
                          <li onClick={() => handleSelect("1stFloor")}>1st Floor</li>
                          <li onClick={() => handleSelect("2ndFloor")}>2nd Floor</li>
                          <li onClick={() => handleSelect("3rdFloor")}>3rd Floor</li>
                          <li onClick={() => handleSelect("4thFloor")}>4th Floor</li>
                          <li onClick={() => handleSelect("5thFloor")}>5th Floor</li>
                          <li onClick={() => handleSelect("6thFloor")}>6th Floor</li>
                          <li onClick={() => handleSelect("7thFloor")}>7th Floor</li>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="pwd">Check In</label>
                      <input type="date" className="form-control" placeholder="Enter Date" id="datein" />
                    </div>
                    <div className="form-group">
                      <label for="pwd">Check Out</label>
                      <input type="date" className="form-control" placeholder="Enter Date" id="dateout" />
                    </div>
                    <div className="form-group">
                      <label for="pwd">Guest</label>
                      <input type="text" className="form-control" placeholder="Enter Date" id="guest" />
                    </div>
                    <button type="submit" className="srarch_bot">Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>

  );
};

export default Component;
