import { Link } from "react-router-dom";
import React, { useState } from 'react';
import "./component.css";

const Component = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState("");

  const handleSelect = (floor) => {
    setSelectedFloor(floor);
    setIsOpen(false);
  };

  return (
    <>
      <div className="my-top" id="features">
        <center>
          <p className="stay-theam">Similer Product</p>
          <hr class="stay-line" />
        </center>
        <div className="product_side">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="product_card">
                  <img src="img/product04.png" className="img-fluid hut-image2" alt="Clients" />
                  <div className="product_text">
                    <p className="product_head">Double Room 5</p>
                    <div className="big">
                      <div className="big_icn">
                        <i class="ri-star-fill star_icn"></i>
                      </div>
                      <div className="big_txt">
                        <p className="product_txt1 m-0">4.5/5 (2 reviews)</p>
                      </div>
                    </div>
                    <div className="big">
                      <div className="big_icn">
                      <i class="ri-drag-move-2-fill star_icn"></i>
                      </div>
                      <div className="big_txt">
                        <p className="product_txt1 m-0">Room size:50sqm</p>
                      </div>
                    </div>
                    <div className="big">
                      <div className="big_icn">
                        {/* <i class="ri-star-fill star_icn"></i> */}
                        <i class="ri-hotel-bed-line star_icn"></i>
                      </div>
                      <div className="big_txt">
                        <p className="product_txt1 m-0">Bed: 1bed</p>
                      </div>
                    </div>
                    <div className="big">
                      <div className="big_icn">
                        <i class="ri-star-fill star_icn"></i>
                      </div>
                      <div className="big_txt">
                        <p className="product_txt1 m-0">4.5/5 (2 reviews)</p>
                      </div>
                    </div>
                    <hr className="line2"/>
                    <div className="price">
                      <div className="price_icn">
                        {/* <i class="ri-star-fill star_icn"></i> */}
                        <p className="product_txt1 m-0">₹1,000/- Night</p>
                      </div>
                      <div className="price_txt">
                        <p className="product_txt1 m-0">View Details</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="product_card">
                  <img src="img/product01.png" className="img-fluid hut-image2" alt="Clients" />
                  <div className="product_text">
                    <p className="product_head">Double Room 5</p>
                    <div className="big">
                      <div className="big_icn">
                        <i class="ri-star-fill star_icn"></i>
                      </div>
                      <div className="big_txt">
                        <p className="product_txt1 m-0">4.5/5 (2 reviews)</p>
                      </div>
                    </div>
                    <div className="big">
                      <div className="big_icn">
                      <i class="ri-drag-move-2-fill star_icn"></i>
                      </div>
                      <div className="big_txt">
                        <p className="product_txt1 m-0">Room size:50sqm</p>
                      </div>
                    </div>
                    <div className="big">
                      <div className="big_icn">
                        {/* <i class="ri-star-fill star_icn"></i> */}
                        <i class="ri-hotel-bed-line star_icn"></i>
                      </div>
                      <div className="big_txt">
                        <p className="product_txt1 m-0">Bed: 1bed</p>
                      </div>
                    </div>
                    <div className="big">
                      <div className="big_icn">
                        <i class="ri-star-fill star_icn"></i>
                      </div>
                      <div className="big_txt">
                        <p className="product_txt1 m-0">4.5/5 (2 reviews)</p>
                      </div>
                    </div>
                    <hr className="line2"/>
                    <div className="price">
                      <div className="price_icn">
                        {/* <i class="ri-star-fill star_icn"></i> */}
                        <p className="product_txt1 m-0">₹1,000/- Night</p>
                      </div>
                      <div className="price_txt">
                        <p className="product_txt1 m-0">View Details</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="product_card">
                  <img src="img/product03.png" className="img-fluid hut-image2" alt="Clients" />
                  <div className="product_text">
                    <p className="product_head">Double Room 5</p>
                    <div className="big">
                      <div className="big_icn">
                        <i class="ri-star-fill star_icn"></i>
                      </div>
                      <div className="big_txt">
                        <p className="product_txt1 m-0">4.5/5 (2 reviews)</p>
                      </div>
                    </div>
                    <div className="big">
                      <div className="big_icn">
                      <i class="ri-drag-move-2-fill star_icn"></i>
                      </div>
                      <div className="big_txt">
                        <p className="product_txt1 m-0">Room size:50sqm</p>
                      </div>
                    </div>
                    <div className="big">
                      <div className="big_icn">
                        {/* <i class="ri-star-fill star_icn"></i> */}
                        <i class="ri-hotel-bed-line star_icn"></i>
                      </div>
                      <div className="big_txt">
                        <p className="product_txt1 m-0">Bed: 1bed</p>
                      </div>
                    </div>
                    <div className="big">
                      <div className="big_icn">
                        <i class="ri-star-fill star_icn"></i>
                      </div>
                      <div className="big_txt">
                        <p className="product_txt1 m-0">4.5/5 (2 reviews)</p>
                      </div>
                    </div>
                    <hr className="line2"/>
                    <div className="price">
                      <div className="price_icn">
                        {/* <i class="ri-star-fill star_icn"></i> */}
                        <p className="product_txt1 m-0">₹1,000/- Night</p>
                      </div>
                      <div className="price_txt">
                        <p className="product_txt1 m-0">View Details</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="product_card">
                  <img src="img/product02.png" className="img-fluid hut-image2" alt="Clients" />
                  <div className="product_text">
                    <p className="product_head">Double Room 5</p>
                    <div className="big">
                      <div className="big_icn">
                        <i class="ri-star-fill star_icn"></i>
                      </div>
                      <div className="big_txt">
                        <p className="product_txt1 m-0">4.5/5 (2 reviews)</p>
                      </div>
                    </div>
                    <div className="big">
                      <div className="big_icn">
                      <i class="ri-drag-move-2-fill star_icn"></i>
                      </div>
                      <div className="big_txt">
                        <p className="product_txt1 m-0">Room size:50sqm</p>
                      </div>
                    </div>
                    <div className="big">
                      <div className="big_icn">
                        {/* <i class="ri-star-fill star_icn"></i> */}
                        <i class="ri-hotel-bed-line star_icn"></i>
                      </div>
                      <div className="big_txt">
                        <p className="product_txt1 m-0">Bed: 1bed</p>
                      </div>
                    </div>
                    <div className="big">
                      <div className="big_icn">
                        <i class="ri-star-fill star_icn"></i>
                      </div>
                      <div className="big_txt">
                        <p className="product_txt1 m-0">4.5/5 (2 reviews)</p>
                      </div>
                    </div>
                    <hr className="line2"/>
                    <div className="price">
                      <div className="price_icn">
                        {/* <i class="ri-star-fill star_icn"></i> */}
                        <p className="product_txt1 m-0">₹1,000/- Night</p>
                      </div>
                      <div className="price_txt">
                        <p className="product_txt1 m-0">View Details</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>

  );
};

export default Component;
